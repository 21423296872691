import { axios } from "../../utils/axios";
import ClientSafeException from "../../exceptions/clientSafeException";
import Cookies from "js-cookie";

export default {
  namespaced: false,
  state: {
    access_token: null,
    user: null,
    kisi_url: null,
    kisi_secret: null,
  },
  mutations: {
    LOGIN(state, { access_token, user, kisi_url, kisi_secret }) {
      state.access_token = access_token;
      state.user = user;
      state.kisi_url = kisi_url;
      state.kisi_secret = kisi_secret;
      Cookies.set(
        "auth",
        { access_token, user, kisi_url, kisi_secret },
        { expires: 7 }
      );
    },
    LOGOUT(state) {
      state.access_token = null;
      state.user = null;
      Cookies.remove("auth");
    },
  },
  getters: {
    getAccessToken: state => state.access_token,
    getAdmin: state => state.user,
    isAuthenticated(state) {
      return !!state.access_token;
    },
    firstLetterOfName(state) {
      if (state.user) {
        return state.user.fullname[0];
      }
    },
    getKisiAuthentication(state) {
      return {
        url: state.kisi_url,
        secret: state.kisi_secret,
      };
    },
  },
  actions: {
    async requestLogin({ commit }, payload) {
      let { data, status } = await axios.post("backoffice/auth/login", payload);

      if (status !== 200) {
        throw new ClientSafeException("Couldn't reach API");
      }

      if (data.status.code !== 200) {
        throw new ClientSafeException("Invalid credentials");
      }

      commit("LOGIN", {
        access_token: data.response.access_token,
        kisi_url: data.response.kisi?.url,
        kisi_secret: data.response.kisi?.secret,
        user: {
          admin_id: data.response.admin.admin_id,
          user_id: data.response.admin.user_id,
          email: data.response.admin.email,
          fullname: data.response.admin.fullname,
          image: data.response.admin.image,
          username: data.response.admin.username,
        },
      });
    },
    loginFromSocial({ commit }, payload) {
      commit("LOGIN", {
        access_token: payload.access_token,
        kisi_url: payload.kisi?.url,
        kisi_secret: payload.kisi?.secret,
        user: {
          admin_id: payload.admin.admin_id,
          user_id: payload.admin.user_id,
          email: payload.admin.email,
          fullname: payload.admin.fullname,
          image: payload.admin.image,
          username: payload.admin.username,
        },
      });
    },
    loginFromCookie({ commit }) {
      let authPayload = Cookies.get("auth")
        ? JSON.parse(Cookies.get("auth"))
        : false;

      if (authPayload && authPayload.access_token) {
        commit("LOGIN", authPayload);
      }
    },
    logout({ commit }) {
      commit("LOGOUT");
    },
  },
};
